import { getSiteSettings } from 'api/cms';
import { AuthProvider } from 'hooks/use-auth';
import { SiteSettingsProvider } from 'hooks/use-siteSettings';
import App, { AppContext } from 'next/app';
import dynamic from 'next/dynamic';
import 'normalize.css';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'react-toastify/dist/ReactToastify.css';
import initSentry from 'services/sentry/client';
import { useStore } from 'store';
import { observeDraftPlanState } from 'store/utils';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme-light';
import { SiteSettings } from 'types/sanity';

// Dynamically import components not needed right away
const ToastContainer = dynamic<any>(() =>
  import('components/ui/toast-container').then((mod) => mod.ToastContainer)
);

// Init react-query
const queryClient = new QueryClient();

/* Enable Sentry */
initSentry();

interface Props {
  siteSettings: SiteSettings;
  /**
   * Not part of the publicly exposed Next API.
   * Workaround for https://github.com/vercel/next.js/issues/8592
   */
  err: any;
}

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-000000',
  auth: process.env.NEXT_PUBLIC_GTM_AUTH,
  preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
};

const StoreObserverLayer: React.FC = (props) => {
  useEffect(() => {
    observeDraftPlanState(useStore);
  }, []);

  return <>{props.children}</>;
};

export default class extends App<Props> {
  static async getInitialProps(appContext: AppContext) {
    const appProps = await App.getInitialProps(appContext);
    const siteSettings = await getSiteSettings();

    return { ...appProps, siteSettings };
  }

  componentDidMount() {
    const initGTM = async () => {
      const TagManager = (await import('react-gtm-module')).default;
      TagManager.initialize(tagManagerArgs);
    };
    initGTM();
  }

  render() {
    const { Component, pageProps, siteSettings, err } = this.props;
    return (
      <StoreObserverLayer>
        <SiteSettingsProvider siteSettings={siteSettings}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <QueryClientProvider client={queryClient}>
                <Component {...pageProps} err={err} />
                <ToastContainer />
                <ReactQueryDevtools />
              </QueryClientProvider>
            </ThemeProvider>
          </AuthProvider>
        </SiteSettingsProvider>
      </StoreObserverLayer>
    );
  }
}
