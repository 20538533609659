import * as Sentry from '@sentry/browser';
import { isDev } from 'utils/helpers';

function initSentry() {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const commitSha = process.env.NEXT_PUBLIC_AWS_COMMIT_ID;
    const buildEnv = process.env.NEXT_PUBLIC_BUILD_ENV || process.env.NODE_ENV; // BUILD_ENV is set by Amplify

    Sentry.init({
      enabled: !isDev(),
      debug: false,
      environment: `${buildEnv}@web`,
      release:
        commitSha && `${process.env.NEXT_PUBLIC_SENTRY_RELEASE}@${commitSha}`,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      tracesSampleRate: 0.2,
    });
  }
}

export default initSentry;
